/* Envelope.css */
.envelope {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 5s ease;
    z-index: 1000;
    background-color: #fde9db;
  }
  
  .envelope-top {
    width: 0;
    height: 0;
    border-top: 50vh solid transparent;
    border-bottom: 50vh solid transparent;
    border-right: 50vw solid #e9ccbb;
    padding-left: 50vw;
    position: relative;
    transition: transform 2s ease;
    transform-origin: right center;
  }
  
  .envelope-bottom {
    width: 100vw;
    height: 100%;
    background-color: #fde9db;
  }
  
  .envelope.opened .envelope-top {
    transform: rotateY(-180deg);
  }
  
  .envelope.opened {
    transform: scale(0);
  }
  
  .envelope-text {
    position: absolute;
    top: 37%;
    writing-mode: vertical-rl;
    transform: translateX(-40px);
    z-index: 1002; /* Ensure it's above the envelope */
    text-align: center;
    font-family: var(--font-family-heading);
  }
  
  .envelope-text2 {
    position: absolute;
    top: 41%;
    writing-mode: vertical-rl;
    transform: translateX(-100px);
    z-index: 1001; /* Ensure it's above the envelope */
    text-align: center;
    font-family: var(--font-family-heading);
    font-size: 1.5rem;
  }
  