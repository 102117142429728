body {
  margin: 0;
  font-family: var(--font-family-base);
  font-weight: 100;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--color-primary);
}
h1 {
  font-family:  var(--font-family-heading);
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 100;
  font-size: 5rem;
  z-index: 1;
}
h2 {
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 100;
  font-size: 3rem;
  z-index: 1;
}

.heading-styled-text {
  font-family: var(--font-family-heading);
}

.big-text {
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 3rem !important;
  z-index: 1;
}

.big-text2 {
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 3.5rem !important;
  z-index: 1;
}


@media (max-width: 600px) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: normal;
  }
  .big-text {
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.8rem !important;
    z-index: 1;
  }
  .big-text2 {
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 2.2rem !important;
    z-index: 1;
  }
  }

.header {
  padding-block: 30px;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('/public/Fondo5.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.header-text {
  position: relative;
  color: white;
  text-align: center;
  z-index: 1;
}
.section {
  padding: 20px;
  background-color: #f4f4f4;
  text-align: center;
}
.section:nth-child(even) {
  background-color: #e4e4e4;
}
.section-black {
  padding: 20px;
  background-color: #3F1F0D;
  text-align: center;
  color:white;
}
.section-black:nth-child(even) {
  background-color: #3F1F0D;
}
.section-blush {
  padding: 20px;
  background-color: #e9ccbb;/*#9D7E73;BB8F79;*/ 
  text-align: center;
}
.section-blush:nth-child(even) {
  background-color: #e9ccbb;
}
.countdown-container {
  flex-direction: column;
  color: white;
}
.countdown {
  display: flex;
  justify-content: center;
  padding-block: 20px;
  border-radius: 10px;
  z-index: 1;
  text-align: center;
  width: 95vw;
}
.countdown div {
  color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time {
  font-size: 1.8rem;
  color: #333;
}
.number {
  font-size: 1.8rem;
  margin-bottom: 5px;
}
.label {
  font-size: 1.8rem;
}

.generic-text {
  margin-bottom: 20px;
  font-size: 2rem;
}
.columns {
  display: flex;
  justify-content: space-between;
}
.columns2 {
  display: flex;
  justify-content: space-between;
}
.column {
  flex: 1;
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .countdown {
      /* flex-direction: column; */
  }
  .countdown div {
      width: 21vw;
      margin: 10px auto;
      padding: 5px;
      font-size: 1.2rem;
      text-align: center;
  }
  .columns {
      flex-direction: column;
  }
  .column {
      margin: 10px 0;
  }
  
.generic-text {
  margin-bottom: 40px;
  font-size: 1.5rem;
}
.button {
  width: 85%;
}
}
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  padding-bottom: 7%;
  background-color: rgba(76, 37, 23, 0.336);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(76, 37, 23, 0.1);
  z-index: 2;
}
.overlay span {
  font-size: 8rem;
  margin-bottom: 10px;
}
.overlay p {
  font-size: 1.5em;
  margin: 0;
}

.dress {
  width: 18rem;
}

@media (max-width: 600px) {
  .overlay {
  width: 95%;
  height: 97%;
  padding: 1%;
  }

  .dress {
    width: 30vw;
  }
}
.circular-image{border-radius:25%; width:250px; height: auto;} 

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: scale(0.5);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}

.fade-in-text {
opacity: 0; /* Inicialmente invisible */
animation: fadeIn 2s ease-in forwards; /* Duración de la animación y efecto de suavizado */
}

.button {
  background-color: #3F1F0D;
  border-color: #3F1F0D;
  border-style: none;
  color: white;
  font-family: var(--font-family-base);
  font-weight: 100;
  font-size: 2rem;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}


.slide-right {
  animation: slideRight 2s forwards;
}

@keyframes slideRight {
  0% {
    transform: translate(-1000px);
    opacity: 0;
}

100% {
    transform: translate(0);
    opacity: 1;
}
}