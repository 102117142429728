@font-face {
  font-family: Tempting;
  src: url(/public/Tempting2.ttf);
}

:root {
  --font-family-base: 'Lora', serif;
  --font-family-highlight: 'Playfair Display', serif;
  --font-family-heading: 'Tempting', sans-serif;
  --font-family-alt: 'Dancing Script', sans-serif;
  --color-primary: #3F1F0D;
  --color-primary-hover: #32190b;
  --color-primary-active: #32190b;
  --color-secondary: #e9ccbb;
  --color-secondary-hover: #e9ccbb;
  --color-secondary-active: #e9ccbb;
  --color-tertiary: #ffca28;
  --color-tertiary-hover: #f2bb05;
  --color-tertiary-active: #e79500;
  --color-success: #008a10;
  --color-success-hover: #00700b;
  --color-success-active: #005c08;
  --color-error: #d8292f;
  --color-error-hover: #b21717;
  --color-error-active: #8b1515;
  --color-overlay: rgba(76, 37, 23, 0.336);
  --font-size-base: 14px;
  --font-size-base-mobile: 14px;
  --font-size-main: 12px;
  --font-size-main-mobile: 14px;
  --font-size-secondary: 14px;
  --font-size-secondary-mobile: 14px;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}