.mute-button {
    position: fixed;
    z-index: 9999;
    bottom: 40px;
    right: 20px;
    background-color: #e9ccbb;
    color: #3F1F0D;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    font-size: large;
  }
  
  /* .mute-button:hover {
    background-color: #ac897c;
  } */
  
  .mute-button:active {
    background-color: #ac897c;
  }

  @media (max-width: 600px) {
    .mute-button {
        width: 75px;
        height: 75px;
      }
  }